import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import '../../styles/src/window/index.scss';
import { Spinner } from '@phosphor-icons/react';
import { useAppProvider, WindowDragPositionType } from '../root/provider';
import type { Icon } from "@phosphor-icons/react";
import { windowContents, WindowContentTemplate } from '../../contents/windows/index';
import { WindowHeader } from './src/header';
import { WindowFooter } from './src/footer';

export interface ATHXWindowProps {
  id: string;
  title: string;
  url: string;
  onClose: (id: string) => void;
  onToggleMinimize: (id: string, isMinimized: boolean) => void;
  onResize?: (id: string) => void;
  onLoaded?: () => void;
  icon: Icon;
  components?: Array<React.ReactNode>;
}

export const ATHXWindow = React.memo<ATHXWindowProps>(({ id, title, url, onClose, onToggleMinimize, onResize, onLoaded, icon }) => {

  /**
   * Importazione di funzioni e costanti da useAppProvider
   */
  const {
    deleteAtxhWindow,
    deleteMiniatureWindow,
    lastAthxWindowFixedPos,
    isAnyAthxWindowDragging,
    lastAthxWindowDraggedId,
    setAnyAthxWindowDraggingState,
    setLastAthxWindowFixedPosState
  } = useAppProvider();

  /**
   * Costante locale per determinare se il dispositivo è mobile
   */
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  /**
   * Costante locale per determinare se la larghezza della finestra è inferiore a 992px
   */
  const lessThanLG = window.innerWidth < 992;

  /**
   * Costanti locali per determinare la larghezza e la posizione LEFT iniziale della finestra
   */
  const [initialWidth, posLeft]: [number, number] = (() => {
    if (lessThanLG || (isMobile && lessThanLG)) return [window.innerWidth, 0];
    const _1perc = window.innerWidth / 100;
    return [_1perc * 80, _1perc * 10];
  })();

  /**
   * Costanti locali per determinare l'altezza e la posizione TOP iniziale della finestra
   */
  const [initialHeight, posTop]: [number, number] = (() => {
    if (lessThanLG || (isMobile && lessThanLG)) return [window.innerHeight - 40, 0];
    const _1perc = window.innerHeight / 100;
    return [_1perc * 80, _1perc * 10];
  })();

  /**
   * 
   */
  // const loadingProcesses = useRef<number>(0);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const currentLastAthxWindowFixedPos = useRef<string | null>(null);
  const windowRef = useRef<HTMLDivElement | null>(null);
  const positionRef = useRef<{ top: number; left: number }>({ top: (isFullscreen || (lessThanLG || (isMobile && lessThanLG))) && !isMinimized ? 0 : posTop, left: (isFullscreen || (lessThanLG || (isMobile && lessThanLG))) && !isMinimized ? 0 : posLeft });
  const sizeRef = useRef<{ width: number; height: number }>({ width: isFullscreen && !isMinimized ? window.innerWidth : initialWidth, height: isFullscreen && !isMinimized ? window.innerHeight - 40 : initialHeight });
  const dragging = useRef<boolean>(false);
  const resizing = useRef<boolean>(false);

  const [IsLoadingBody, setIsLoadingBody] = useState<boolean>(false);
  const [IsLoadingMenu, setIsLoadingMenu] = useState<boolean>(false);

  const [BodyContent, setBodyContent] = useState<React.LazyExoticComponent<React.ComponentType<any>> | null>(null);
  const [MenuContent, setMenuContent] = useState<React.LazyExoticComponent<React.ComponentType<any>> | null>(null);



  /**
   * Caricamento dinamico del contenuto e del menu della finestra
   */
  useEffect(() => {
    if (!url)
      return;


    /**
     * 
     * @param ms il tempo di attesa in millisecondi
     * @returns void
     * @description fa da delay per l'esecuzione del codice
     */

    setIsLoadingBody(true);
    setIsLoadingMenu(true);

    try {

      /**Importiamo il body ed il menu dalla mappa */
      const { BodyContent, MenuContent }: WindowContentTemplate = url in windowContents ? windowContents[url] : windowContents.loadingError;

      /**
       * Blocco caricamento N°1
       * Viene caricato il contenuto principale (body) della finestra
       */

      try {
        setBodyContent(() => lazy(BodyContent));
        // await sleep(500);
      } catch (bodyContentLoadingError) {
        console.error(bodyContentLoadingError);
      } finally {
        setIsLoadingBody(false);
      }

      /**
       * Blocco caricamento N°2
       * Viene caricato il menu della finestra
       */
      try {
        if (MenuContent !== null) {
          setMenuContent(() => lazy(MenuContent));
        }
      } catch (menuContentLoadingError) {
        console.error(menuContentLoadingError);
      } finally {
        setIsLoadingMenu(false);
      }


      /**
       * Callback di caricamento completato
       */
      onLoaded && onLoaded();
    } catch (e) {
      console.error(e);
    }
    // }
    // startLoading();

  }, [url, onLoaded, setMenuContent, setBodyContent, setIsLoadingBody, setIsLoadingMenu]);



  /**
   * Gestione delle classi e attributi della finestra in base alle sue dimensioni
   */
  useEffect(() => {
    if (!windowRef.current) return;

    /**
     * 
     * @param {ResizeObserverEntry[]} entries le entità osservate
     * @returns {void} 
     */
    const observerCallback = (entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {

        /**
         * Accertazione che la finestra sia presente
         */
        if (!windowRef.current) return;

        /**
         * Estrazione della larghezza della finestra
         */
        const { width } = entry.contentRect;

        /**
         * Vengono rimosse tutte le classi di larghezza
         */
        windowRef.current.classList.remove('ws-w-xs', 'ws-w-sm', 'ws-w-md', 'ws-w-lg', 'ws-w-xl', 'ws-w-xxl');

        /**
         * Esecuzione custom callback 
         */


        onResize && onResize(id);

        /**
         * 
         * @param className classe da aggiungere
         * @param include misure da includere
         * @param exclude misure da escludere
         * @returns void
         */
        const customizeWindow = (className: string, include: string, exclude: string): void => {
          /**
           * Accertazione che la finestra sia presente
           */
          if (!windowRef.current) return;

          /**
           * Applicazioni delle modifiche per la responsivity della finestra
           */
          windowRef.current.classList.add(className);
          windowRef.current.setAttribute('include', include);
          windowRef.current.setAttribute('exclude', exclude);
        }

        /**
         * In base a quanto è larga, vengono applicate apposite classi e attributi
         */

        /**
         * XXL
         */
        if (width >= 1400)
          customizeWindow('ws-w-xxl', '-xs-sm-md-lg-xl-xxl-', 'xs-sm-md-lg-xl');

        /**
         * XL
         */
        else if (width >= 1200)
          customizeWindow('ws-w-xl', '-xs-sm-md-lg-xl-', '-xs-sm-md-lg-');

        /**
         * LG
         */
        else if (width >= 992)
          customizeWindow('ws-w-lg', '-xs-sm-md-lg-', '-xs-sm-md-');

        /**
         * MD
         */
        else if (width >= 768)
          customizeWindow('ws-w-md', '-xs-sm-md-', '-xs-sm-');

        /**
         * SM
         */
        else if (width >= 576)
          customizeWindow('ws-w-sm', '-xs-sm-', '-xs-');

        /**
         * XS
         */
        else
          customizeWindow('ws-w-xs', '-xs-', '-');

      }
    }
    const resizeObserver = new ResizeObserver(observerCallback);

    /**
     * Applicazione dell'observer alla finestra
     */
    resizeObserver.observe(windowRef.current);

    /**
     * Callback di cleanup
     */
    return () => {
      resizeObserver.disconnect();
    };
  }, [onResize, id]);

  const handleFullscreen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsFullscreen(!isFullscreen);
  };

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
    onToggleMinimize(id, !isMinimized);
  };

  const setAthxWindowFixedPosition = (pos: WindowDragPositionType) => {
    lastAthxWindowFixedPos.current = pos;
    currentLastAthxWindowFixedPos.current = pos;
    setLastAthxWindowFixedPosState(pos);
  }

  const handleDestroy = () => {
    if (windowRef.current) 
      windowRef.current.remove();
    deleteAtxhWindow(id);
    deleteMiniatureWindow(id);
    onClose(id);
  };

  const handleMouseDown = (e: React.MouseEvent | React.TouchEvent<HTMLDivElement>) => {
    if(isFullscreen || resizing.current) return;
    dragging.current = true;

    isAnyAthxWindowDragging.current = true;
    setAnyAthxWindowDraggingState(true);
    if (lastAthxWindowDraggedId.current !== id)
      lastAthxWindowDraggedId.current = id;

    setAthxWindowFixedPosition(null);

    const startX = 'clientX' in e ? e.clientX : e.touches[0].clientX;
    const startY = 'clientY' in e ? e.clientY : e.touches[0].clientY;
    const { top, left } = positionRef.current;

    const handleMouseMove = (moveEvent: MouseEvent | TouchEvent) => {
      if (!dragging.current) return;
      let newLeft = left + ((moveEvent instanceof MouseEvent ? moveEvent.clientX : moveEvent.touches[0].clientX) - startX);
      let newTop = top + ((moveEvent instanceof MouseEvent ? moveEvent.clientY : moveEvent.touches[0].clientY) - startY);

      const widthAx = moveEvent instanceof MouseEvent ? moveEvent.clientX : moveEvent.touches[0].clientX;
      const heightAx = moveEvent instanceof MouseEvent ? moveEvent.clientY : moveEvent.touches[0].clientY;

      const wWidth = window.innerWidth;
      const wHeight = window.innerHeight - (3.5 * 16);

      const perc15_width = parseInt(String(wWidth * 0.15));
      const perc15_height = parseInt(String(wHeight * 0.15));

      const prePosTop = ((heightAx < 20) && (widthAx >= perc15_width) && (widthAx <= (wWidth - perc15_width))) || ((heightAx > (wHeight - 20)) && (heightAx < (window.innerHeight)) && (widthAx >= perc15_width) && (widthAx <= (wWidth - perc15_width)));
      const preLeftPos = widthAx < 20 && heightAx >= perc15_height && heightAx <= (wHeight - perc15_height);
      const preTopLeftPos = (heightAx < 20 && widthAx < perc15_width) || (widthAx < 20 && heightAx < perc15_height);
      const preBottomLeftPos = (widthAx < 20 && heightAx > (wHeight - perc15_height) && heightAx < wHeight) || (heightAx > (wHeight - 20) && heightAx < wHeight && widthAx < perc15_width);

      const preTopRightPos = (heightAx < 20 && widthAx > (wWidth - perc15_width) && widthAx < wWidth) || (widthAx > (wWidth - 20) && widthAx < wWidth && heightAx < perc15_height);
      const preRightPos = widthAx > (wWidth - 20) && widthAx < wWidth && heightAx >= perc15_height && heightAx <= (wHeight - perc15_height);
      const preBottomRightPost = (widthAx > (wWidth - 20) && widthAx < wWidth && heightAx > (wHeight - perc15_height) && heightAx < wHeight) || (heightAx > (wHeight - 20) && heightAx < wHeight && widthAx > (wWidth - perc15_width) && widthAx < wWidth);

      let newPos: WindowDragPositionType = null;
      if (prePosTop)
        newPos = 'top';
      else if (preLeftPos)
        newPos = 'left';
      else if (preTopLeftPos)
        newPos = 'top-left';
      else if (preBottomLeftPos)
        newPos = 'bottom-left';
      else if (preTopRightPos)
        newPos = 'top-right';
      else if (preRightPos)
        newPos = 'right';
      else if (preBottomRightPost)
        newPos = 'bottom-right';

      setAthxWindowFixedPosition(newPos);



      // newLeft = Math.max(0, Math.min(newLeft, window.innerWidth - sizeRef.current.width));
      // newTop = Math.max(0, Math.min(newTop, window.innerHeight - sizeRef.current.height));

    // check if the window is out of the screen
    if(newLeft < (0 - (sizeRef.current.width / 2))) newLeft = 0 - (sizeRef.current.width / 2);
    if(newLeft > (window.innerWidth - (sizeRef.current.width / 2))) newLeft = window.innerWidth - (sizeRef.current.width / 2);
    if(newTop < (0 - (sizeRef.current.height / 2))) newTop = 0 - (sizeRef.current.height / 2);
    if(newTop > (window.innerHeight - (sizeRef.current.height / 2))) newTop = window.innerHeight - (sizeRef.current.height / 2);


      positionRef.current = { top: newTop, left: newLeft };
      if (windowRef.current && newPos === null) {
        windowRef.current.style.top = `${newTop}px`;
        windowRef.current.style.left = `${newLeft}px`;
      }
    };

    const handleMouseUp = () => {
      dragging.current = false;

      isAnyAthxWindowDragging.current = false;
      setAnyAthxWindowDraggingState(false);



      positionRef.current = { ...positionRef.current };

      (() => {
        if (id !== lastAthxWindowDraggedId.current)
          return;
        if (!windowRef.current)
          return;
        if (dragging.current)
          return;
        if (currentLastAthxWindowFixedPos.current === null)
          return;
        const wWidth = window.innerWidth;
        const wHeight = window.innerHeight - (3.5 * 16);
        const perc_50_width = Math.floor(wWidth * 0.50);
        const perc_50_height = Math.floor(wHeight * 0.50);

        if (currentLastAthxWindowFixedPos.current === 'top') {
          /*+5*/positionRef.current = { top: 5, left: 5 };
          /*-10*/sizeRef.current = { width: wWidth - 10, height: wHeight - 10 };
        } else if (currentLastAthxWindowFixedPos.current === 'left') {
          /*+5*/positionRef.current = { top: 5, left: 5 };
          /*-10*/sizeRef.current = { width: perc_50_width - 10, height: wHeight - 10 };
        } else if (currentLastAthxWindowFixedPos.current === 'right') {
          /*+5*/positionRef.current = { top: 5, left: perc_50_width + 5 };
          /*-10*/sizeRef.current = { width: perc_50_width - 10, height: wHeight - 10 };
        } else if (currentLastAthxWindowFixedPos.current === 'top-left') {
          /*+5*/positionRef.current = { top: 5, left: 5 };
          /*-10*/sizeRef.current = { width: perc_50_width - 10, height: perc_50_height - 10 };
        } else if (currentLastAthxWindowFixedPos.current === 'top-right') {
          /*+5*/positionRef.current = { top: 5, left: perc_50_width + 5 };
          /*-10*/sizeRef.current = { width: perc_50_width - 10, height: perc_50_height - 10 };
        } else if (currentLastAthxWindowFixedPos.current === 'bottom-left') {
          /*+5*/positionRef.current = { top: perc_50_height + 5, left: 5 };
          /*-10*/sizeRef.current = { width: perc_50_width - 10, height: perc_50_height - 10 };
        } else if (currentLastAthxWindowFixedPos.current === 'bottom-right') {
          /*+5*/positionRef.current = { top: perc_50_height + 5, left: perc_50_width + 5 };
          /*-10*/sizeRef.current = { width: perc_50_width - 10, height: perc_50_height - 10 };
        }

        if (windowRef.current) {
          windowRef.current.style.top = `${positionRef.current.top}px`;
          windowRef.current.style.left = `${positionRef.current.left}px`;
          windowRef.current.style.width = `${sizeRef.current.width}px`;
          windowRef.current.style.height = `${sizeRef.current.height}px`;
          windowRef.current.classList.add('fixed');
        }


      })()

      if (currentLastAthxWindowFixedPos.current !== null)
        windowRef.current && windowRef.current.classList.add('fixed');
      else
        windowRef.current && windowRef.current.classList.remove('fixed');
      setAthxWindowFixedPosition(null);
      lastAthxWindowDraggedId.current = null;

      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('touchend', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchmove', handleMouseMove);
    window.addEventListener('touchend', handleMouseUp);
  };

  const handleResizeMouseDown = (e: React.MouseEvent | React.TouchEvent<HTMLDivElement>, direction: string) => {
    if(isFullscreen || dragging.current) return;
    resizing.current = true;
    const startX = 'clientX' in e ? e.clientX : e.touches[0].clientX;
    const startY = 'clientY' in e ? e.clientY : e.touches[0].clientY;
    const { width, height } = sizeRef.current;
    const { top, left } = positionRef.current;

    const handleMouseMove = (moveEvent: MouseEvent | TouchEvent) => {
      if (!resizing.current) return;
      let newWidth = width;
      let newHeight = height;
      let newTop = top;
      let newLeft = left;

      if (direction.includes('right')) {
        newWidth = width + ((moveEvent instanceof MouseEvent ? moveEvent.clientX : moveEvent.touches[0].clientX) - startX);
      }
      if (direction.includes('bottom')) {
        newHeight = height + ((moveEvent instanceof MouseEvent ? moveEvent.clientY : moveEvent.touches[0].clientY) - startY);
      }
      if (direction.includes('left')) {
        newWidth = width - ((moveEvent instanceof MouseEvent ? moveEvent.clientX : moveEvent.touches[0].clientX) - startX);
        newLeft = left + ((moveEvent instanceof MouseEvent ? moveEvent.clientX : moveEvent.touches[0].clientX) - startX);
      }
      if (direction.includes('top')) {
        newHeight = height - ((moveEvent instanceof MouseEvent ? moveEvent.clientY : moveEvent.touches[0].clientY) - startY);
        newTop = top + ((moveEvent instanceof MouseEvent ? moveEvent.clientY : moveEvent.touches[0].clientY) - startY);
      }

      newWidth = newWidth < 300 ? 300 : newWidth;
      newHeight = newHeight < 400 ? 400 : newHeight;

      sizeRef.current = { width: newWidth, height: newHeight };
      positionRef.current = { top: newTop, left: newLeft };
      if (windowRef.current) {
        windowRef.current.style.width = `${newWidth}px`;
        windowRef.current.style.height = `${newHeight}px`;
        windowRef.current.style.top = `${newTop}px`;
        windowRef.current.style.left = `${newLeft}px`;
      }
    };

    const handleMouseUp = () => {
      resizing.current = false;
      sizeRef.current = { ...sizeRef.current };
      positionRef.current = { ...positionRef.current };
      windowRef.current && windowRef.current.classList.remove('fixed');
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('touchend', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchmove', handleMouseMove);
    window.addEventListener('touchend', handleMouseUp);
  };

  useEffect(() => {
    if (windowRef.current) {
      document.querySelectorAll('.athx-window').forEach((_win: Element) => {
        if (_win !== windowRef.current) {
          _win.classList.remove('active');
          (_win as HTMLElement).style.zIndex = '0';
        }
      });
      windowRef.current.classList.add('active');
      windowRef.current.style.zIndex = '1';
    }
  }, []);

  const handleActivate = () => {
    if (windowRef.current) {
      document.querySelectorAll('.athx-window').forEach((_win: Element) => {
        if (_win !== windowRef.current) {
          _win.classList.remove('active');
          (_win as HTMLElement).style.zIndex = '0';
        }
      });
      windowRef.current.classList.add('active');
      windowRef.current.style.zIndex = '1';
    }
  };

  const style = { top: positionRef.current.top, left: positionRef.current.left, width: sizeRef.current.width, height: sizeRef.current.height }
  const className = `athx-window ${isFullscreen ? 'fullscreen' : ''} ${isMinimized ? 'minimized' : ''}`
  return (
    <div className="athx-window-container" style={{ position: 'absolute' }} id={id}>
      <div ref={windowRef} className={className} style={style} onMouseDown={handleActivate} onTouchStart={handleActivate}>
        <div className="athx-window-resizer t" onMouseDown={(e) => handleResizeMouseDown(e, 'top')} onTouchStart={(e) => handleResizeMouseDown(e, 'top')}></div>
        <WindowHeader icon={icon} titleContent={title} handleMinimize={handleMinimize} handleFullscreen={handleFullscreen} handleDestroy={handleDestroy} handleMouseDown={handleMouseDown} isFullscreen={isFullscreen} />
        <div className="athx-window-body">
          <div className="athx-window-resizer l" onMouseDown={(e) => handleResizeMouseDown(e, 'left')} onTouchStart={(e) => handleResizeMouseDown(e, 'left')}></div>
          
          <div className="athx-window-content-container">
          {IsLoadingMenu ? (
              <div className="athx-window-menu">Caricamento menu in corso...</div>
            ) : (MenuContent && (
              <Suspense>
                <MenuContent />
              </Suspense>))
            }
            {IsLoadingBody ? (<div className="loading-overlay"><span><Spinner size={64} className='spinner' />Caricamento in corso...</span></div>) : (

              BodyContent && (
                <Suspense fallback={<div className="loading-overlay"><span><Spinner size={64} className='spinner' />Caricamento in corso...</span></div>}>
                  <BodyContent />
                </Suspense>
              )
            )}

          </div>
          <div className="athx-window-resizer r" onMouseDown={(e) => handleResizeMouseDown(e, 'right')} onTouchStart={(e) => handleResizeMouseDown(e, 'right')}></div>
        </div>
        <WindowFooter />
        <div className="athx-window-resizer b" onMouseDown={(e) => handleResizeMouseDown(e, 'bottom')} onTouchStart={(e) => handleResizeMouseDown(e, 'bottom')}></div>
        <div className="athx-window-resizer a-tl" onMouseDown={(e) => handleResizeMouseDown(e, 'top-left')} onTouchStart={(e) => handleResizeMouseDown(e, 'top-left')}></div>
        <div className="athx-window-resizer a-tr" onMouseDown={(e) => handleResizeMouseDown(e, 'top-right')} onTouchStart={(e) => handleResizeMouseDown(e, 'top-right')}></div>
        <div className="athx-window-resizer a-bl" onMouseDown={(e) => handleResizeMouseDown(e, 'bottom-left')} onTouchStart={(e) => handleResizeMouseDown(e, 'bottom-left')}></div>
        <div className="athx-window-resizer a-br" onMouseDown={(e) => handleResizeMouseDown(e, 'bottom-right')} onTouchStart={(e) => handleResizeMouseDown(e, 'bottom-right')}></div>
      </div>
    </div>
  );
});
