import React, { FC } from "react"
import "../../styles/src/dekstop/index.scss"
import { DesktopBody } from "./src/desktopbody";



export const Desktop: FC = () => {
  return (
    <div id="desktop">
      <DesktopBody/>
    </div>
  )
}