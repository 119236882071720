import React, { useRef, useEffect } from "react";
import { useMenu } from "..";
import "../../../styles/src/menus/app/index.scss";
import { MenuHeader } from "../src/menu-header";
export function AppMenu() {
  const { isOpen: { app: isOpenAppMenu }, isFullScreen: { app: isFullScreenAppMenu }, toggleFullScreen, setMenuRef } = useMenu();
  const menuRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => { setMenuRef('app', menuRef.current); return () => { setMenuRef('app', null); }; }, [setMenuRef]);
  const handleFullscreenClick = (event: React.MouseEvent<HTMLButtonElement>) => { event.stopPropagation(); toggleFullScreen('app'); };
  return (
    <div ref={menuRef} className={`athenorix-menu athenorix-menu-large app-menu ${isOpenAppMenu ? 'open' : ''} ${isFullScreenAppMenu ? 'fullscreen' : ''}`}>
      <MenuHeader title="Applicazioni" haveFullscreen={true} handleClick={handleFullscreenClick} isInFullScreen={isFullScreenAppMenu} isOpen={isOpenAppMenu}></MenuHeader>
    </div>
  );
}