// store/sideMenuSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SideMenuState {
  menus: {
    [key: string]: boolean; // Ogni chiave rappresenta un SideMenu, con true/false per opened
  };
}

const initialState: SideMenuState = {
  menus: {},
};

const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    toggleSideMenu(state, action: PayloadAction<string>) {
      const id = action.payload;
      state.menus[id] = !state.menus[id];
    },
    setSideMenuOpened(state, action: PayloadAction<{ id: string; opened: boolean }>) {
      const { id, opened } = action.payload;
      state.menus[id] = opened;
    }
    
  },
});

export const { toggleSideMenu, setSideMenuOpened } = sideMenuSlice.actions;
export default sideMenuSlice.reducer;
