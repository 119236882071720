import React, { useEffect, useRef } from "react";
import { useMenu } from "..";
import "../../../styles/src/menus/app/index.scss";
import { MenuHeader } from "../src/menu-header";
import { MenuBody } from "../src/menu-body";
import { MessagesMinBody } from "./src/body/min";
import { MessagesMaxBody } from "./src/body/max";
export function MessagesMenu() {
  const { 
    isOpen: { messages: isOpenMessagesMenu }, isFullScreen: { messages: isFullScreenMessagesMenu }, toggleFullScreen, setMenuRef } = useMenu();
  const menuRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    setMenuRef('messages', menuRef.current);
    return () => {
      setMenuRef('messages', null);
    };
  }, [setMenuRef]);
  const handleFullscreenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleFullScreen('messages');
  };
  return (
    <div ref={menuRef} className={`athenorix-menu athenorix-menu-middle messages-menu ${isOpenMessagesMenu ? 'open' : ''} ${isFullScreenMessagesMenu ? 'fullscreen' : ''}`}>
      <MenuHeader title="Messaggistica" haveFullscreen={true} handleClick={handleFullscreenClick} isInFullScreen={isFullScreenMessagesMenu} isOpen={isOpenMessagesMenu}></MenuHeader>
      <MenuBody>
        <MessagesMinBody></MessagesMinBody>
        <MessagesMaxBody></MessagesMaxBody>
      </MenuBody>
    </div>
  );
}