import React, { useEffect, useRef } from "react";
import { useMenu } from "..";
import "../../../styles/src/menus/app/index.scss";
import { MenuHeader } from "../src/menu-header";
export function CalendarMenu() {
  const { isOpen: { calendar: isOpenCalendarMenu },  isFullScreen: { calendar: isFullScreenCalendarMenu }, toggleFullScreen, setMenuRef } = useMenu();
  const menuRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => { setMenuRef('calendar', menuRef.current); return () => { setMenuRef('calendar', null); }; }, [setMenuRef]);
  const handleFullscreenClick = (event: React.MouseEvent<HTMLButtonElement>) => { event.stopPropagation(); toggleFullScreen('calendar'); };
  return (
    <div ref={menuRef} className={`athenorix-menu athenorix-menu-middle calendar-menu ${isOpenCalendarMenu ? 'open' : ''} ${isFullScreenCalendarMenu ? 'fullscreen' : ''}`}>
      <MenuHeader title="Calendario" haveFullscreen={true} handleClick={handleFullscreenClick} isInFullScreen={isFullScreenCalendarMenu} isOpen={isOpenCalendarMenu}></MenuHeader>
    </div>
  );
}