import { configureStore } from '@reduxjs/toolkit';
import sideMenuReducer from './window/side/index';

export const store = configureStore({
  reducer: {
    sideMenu: sideMenuReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
