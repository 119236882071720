import { Checks, Check } from "@phosphor-icons/react";
export function MessageMenuBodyItem({ imgSrc, name, message, time, count, lastMessageFromReceiver, read, onClick }: { imgSrc?: string, name: string, message: string, time: string, count: number, lastMessageFromReceiver?: boolean, read: boolean, onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void }) {
  const TextFormatter = ({ text, isFromReceiver }: { text: string, isFromReceiver?: boolean }) => (<span>{isFromReceiver && <span>Tu:</span>} {text}</span>);
  return (
    <li>
      <a href="#asd" onClick={onClick}>
        <div className="msg-img">
          <img src={imgSrc} alt="" />
        </div>
        <div className="msg-body">
          <div className="msg-from">{name}</div>
          <div className={`msg-msg ${lastMessageFromReceiver ? 'from-receiver' : ''} ${read ? 'read' : ''}`}>
            {<TextFormatter text={message} isFromReceiver={lastMessageFromReceiver}></TextFormatter>}
          </div>
        </div>
        <div className="msg-desk">
          <span className="msg-time">{time}</span>
          {lastMessageFromReceiver
            ? (<span><span>
              {read
                ? <Check size={20} />
                : <Checks size={20} />
              } </span></span>)
            : (count > 0
              ? <span><span className="msg-count">{count}</span></span>
              : <span><span></span></span>)}
        </div>
      </a>
    </li>
  );
}