import { FC } from 'react';
import { useAppProvider } from '../../root/provider';
import { AppWindow } from '@phosphor-icons/react';
interface CenterMenuProps {
  buttons?: { id: string; toggleMinimize: () => void }[];
}

export const CenterMenu: FC<CenterMenuProps> = ({ buttons }) => {
  const { athxWindowsList } = useAppProvider();
  return (
    <div className="f-item center-menu">
      <button className={`f-menu-trigger active-list-toggler ${athxWindowsList.length > 0 ? 'active' : ''}`}>
        <AppWindow size={36} />
      </button>
    </div>
  );
};