import React from "react";
import { useMenu } from "../../menus";
import { MagnifyingGlass } from '@phosphor-icons/react'

export function LeftMenu() {
  const { toggleMenu, isOpen: { app: isOpenAppMenu, search: isOpenSearchMenu } } = useMenu();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); 
    toggleMenu('app'); 
  };

  const handleSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); 
    toggleMenu('search'); 
  }

  return (
    <div className="f-item left-menu">
      <button className={`f-menu-trigger f-app-menu ${isOpenAppMenu ? 'active' : ''}`} onClick={handleClick}></button>
      <button className={`f-menu-trigger f-search-menu ${isOpenSearchMenu ? 'active' : ''}`} onClick={handleSearch}><MagnifyingGlass size={32} weight="bold"/></button>      
    </div>
  );
}