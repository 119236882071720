import React, { useEffect, useRef } from "react";
import { useMenu } from "..";
import "../../../styles/src/menus/app/index.scss";
import { MenuHeader } from "../src/menu-header";
import { MenuBody } from "../src/menu-body";
import { ProcessMinBody } from "./src/body/min";
export function MiniMenu() {
  const { isOpen: { mini: isOpenMiniMenu },   isFullScreen: { mini: isFullScreenMiniMenu }, toggleFullScreen, setMenuRef } = useMenu();
  const menuRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => { setMenuRef('mini', menuRef.current); return () => { setMenuRef('mini', null); }; }, [setMenuRef]);
  const handleFullscreenClick = (event: React.MouseEvent<HTMLButtonElement>) => { event.stopPropagation(); toggleFullScreen('mini'); };
  return (
    <div ref={menuRef} className={`athenorix-menu athenorix-menu-small mini-menu ${isOpenMiniMenu ? 'open' : ''} ${isFullScreenMiniMenu ? 'fullscreen' : ''}`}>
      <MenuHeader title="Processi in corso" haveFullscreen={false} handleClick={handleFullscreenClick} isInFullScreen={isFullScreenMiniMenu} isOpen={isOpenMiniMenu}></MenuHeader>
      <MenuBody>
        <ProcessMinBody></ProcessMinBody>
      </MenuBody>
    </div>
  );
}