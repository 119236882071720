import React, { useEffect, useRef } from "react";
import { useMenu } from "..";
import "../../../styles/src/menus/app/index.scss";
import { MenuHeader } from "../src/menu-header";
export function EmailsMenu() {
  const { isOpen: { email: isOpenEmailsMenu},   isFullScreen: { email: isFullScreenEmailsMenu}, toggleFullScreen, setMenuRef } = useMenu();
  const menuRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => { setMenuRef('email', menuRef.current); return () => { setMenuRef('email', null); }; }, [setMenuRef]);
  const handleFullscreenClick = (event: React.MouseEvent<HTMLButtonElement>) => { event.stopPropagation(); toggleFullScreen('email'); };
  return (
    <div ref={menuRef} className={`athenorix-menu athenorix-menu-middle email-menu ${isOpenEmailsMenu ? 'open' : ''} ${isFullScreenEmailsMenu ? 'fullscreen' : ''}`}>
      <MenuHeader title="Posta elettronica" haveFullscreen={true} handleClick={handleFullscreenClick} isInFullScreen={isFullScreenEmailsMenu} isOpen={isOpenEmailsMenu}></MenuHeader>
    </div>
  );
}