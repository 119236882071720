import React from "react";
import { ArrowsIn, ArrowsOut, Lock as LockIcon, X as IconX } from "@phosphor-icons/react";
export interface WindowHeaderProps {
  icon: any;
  titleContent: string;
  handleMinimize: () => void;
  handleFullscreen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleDestroy: () => void;
  handleMouseDown: (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => void;
  isFullscreen: boolean;
}
export const WindowHeader: React.FC<WindowHeaderProps> = ({ icon, titleContent, handleMinimize, handleFullscreen, handleDestroy, handleMouseDown, isFullscreen }) => {
  return (
    <div className="athx-window-header" onMouseDown={handleMouseDown} onTouchStart={handleMouseDown}>
      <h4 className="athx-window-title">
        <span className="athx-window-locked text-interactive">
          {<LockIcon size={18} />}
        </span>
        {React.createElement(icon, { size: 18 })}
        {titleContent}
      </h4>
      <div className="athx-window-actions">
        <button className="athx-window-action m" onClick={handleMinimize}>_</button>
        <button className={`athx-window-action f ${isFullscreen ? 'active' : ''}`} onClick={handleFullscreen}>{isFullscreen ? <ArrowsIn size={24} /> : <ArrowsOut size={24} />}</button>
        <button className="athx-window-action d" onClick={handleDestroy}><IconX size={24} /></button>
      </div>
    </div>
  )
}