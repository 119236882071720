import React, { useEffect, useRef } from "react";
import { useMenu } from "..";
import "../../../styles/src/menus/notifications/index.scss";
import { MenuHeader } from "../src/menu-header";
export function NotificationsMenu() {
  const { isOpen: { notification: isOpenNotificationsMenu },  isFullScreen: { notification: isFullScreenNotificationsMenu }, toggleFullScreen, setMenuRef } = useMenu();
  const menuRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => { setMenuRef('notification', menuRef.current); return () => { setMenuRef('notification', null); }; }, [setMenuRef]);
  const handleFullscreenClick = (event: React.MouseEvent<HTMLButtonElement>) => { event.stopPropagation(); toggleFullScreen('notification'); };
  return (
    <div ref={menuRef} className={`athenorix-menu athenorix-menu-middle notifications-menu ${isOpenNotificationsMenu ? 'open' : ''}  ${isFullScreenNotificationsMenu ? 'fullscreen' : ''}`}>
      <MenuHeader title="Notifiche" haveFullscreen={true} handleClick={handleFullscreenClick} isInFullScreen={isFullScreenNotificationsMenu} isOpen={isOpenNotificationsMenu}></MenuHeader>
    </div>
  );
}