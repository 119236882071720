import React, { FC, useEffect } from 'react';
import { X } from '@phosphor-icons/react';
import { useAppProvider } from '../../../../components/root/provider';
export interface ButtonWindowOpenerProps {
  iconName: string;
  url: string;
  icon: any;
  title: string;
  id: string;
  onClose: () => void;
  onToggleMinimize: () => void;
}
export const ButtonWindowOpener: FC<ButtonWindowOpenerProps> = ({ iconName, url, icon, title, id, onClose, onToggleMinimize }) => {
  const { addAthxWindow, athxWindowsList } = useAppProvider();
  const handleDoubleClick = () => {
    const window = athxWindowsList.find(w => w.id === id);
    if (window) return;
    addAthxWindow({
      id: id,
      key: '',
      title: title,
      url: url,
      onClose: onClose,
      onToggleMinimize: onToggleMinimize,
      icon: icon
    });
  }
  const handleContextMenu = (e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
    // e.preventDefault();
    // const elementXY = e.currentTarget.getBoundingClientRect();

    // const clientXY = {
    //   x: e instanceof MouseEvent ? e.clientX : e instanceof TouchEvent ? e.touches[0].clientX : 0,
    //   y: e instanceof MouseEvent ? e.clientY : e instanceof TouchEvent ? e.touches[0].clientY : 0
    // }
    // const insideXY = {
    //   x: elementXY.left - clientXY.x,
    //   y: elementXY.top - clientXY.y
    // }
    // console.log(insideXY);
  }

  return (
    <button className="testbtn" onDoubleClick={handleDoubleClick} onContextMenu={handleContextMenu}>
      <X size={48} />
      <span>{iconName}</span>
    </button>
  )
}