import { useAppProvider } from "../../../../root/provider"
// "top" | "left" | "right" | "top-left" | "top-right" | "bottom-left" | "bottom-right" | null
export const WindowPlaceholders = () => {
  const { isAnyAthxWindowDraggingState, lastAthxWindowFixedPosState } = useAppProvider();
  return (
    <div className={`athx-window-placeholders ${isAnyAthxWindowDraggingState && lastAthxWindowFixedPosState != null ? 'dragging' : ''}`}>
      <div>
        <div className={`top ${lastAthxWindowFixedPosState === 'top' ? 'active' : ''}`}></div>
        <div className={`left ${lastAthxWindowFixedPosState === 'left' ? 'active' : ''}`}></div>
        <div className={`right ${lastAthxWindowFixedPosState === 'right' ? 'active' : ''}`}></div>
        <div className={`top-left ${lastAthxWindowFixedPosState === 'top-left' ? 'active' : ''}`}></div>
        <div className={`bottom-left ${lastAthxWindowFixedPosState === 'bottom-left' ? 'active' : ''}`}></div>
        <div className={`top-right ${lastAthxWindowFixedPosState === 'top-right' ? 'active' : ''}`}></div>
        <div className={`bottom-right ${lastAthxWindowFixedPosState === 'bottom-right' ? 'active' : ''}`}></div>
      </div>
    </div>
  )
}