import React from "react";
import { useMenu } from "../../menus";
import { CaretUp, Chats, ListChecks, CalendarDots, Bell, Mailbox } from "@phosphor-icons/react";

export function RightMenu() {
  const {
    isOpen: { messages: isOpenMessagesMenu, calendar: isOpenCalendarMenu, email: isOpenEmailsMenu, mini: isOpenMiniMenu, notification: isOpenNotificationsMenu, tasks: isOpenTasksMenu }, toggleMenu

  } = useMenu();
  const handleMessagesMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleMenu('messages');
  };
  const handleCalendarMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleMenu('calendar');
  };
  const handleEmailsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleMenu('email');
  };
  const handleMiniMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleMenu('mini');
  };
  const handleNotificationMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleMenu('notification');
  };
  const handleTasksMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleMenu('tasks');
  };


  return (
    <div className="f-item right-menu">
      <button className={`f-menu-trigger f-mini-menu ${isOpenMiniMenu ? 'active' : ''}`} onClick={handleMiniMenuClick}>
        <CaretUp size={32} weight="bold" />
      </button>
      <button className={`f-menu-trigger f-messages-menu ${isOpenMessagesMenu ? 'active' : ''}`} onClick={handleMessagesMenuClick}>
        <Chats size={32} weight="bold" />
      </button>
      <button className={`f-menu-trigger f-tasks-menu ${isOpenTasksMenu ? 'active' : ''}`} onClick={handleTasksMenuClick}>
        <ListChecks size={32} weight="bold" />
      </button>
      <button className={`f-menu-trigger f-calendar-menu ${isOpenCalendarMenu ? 'active' : ''}`} onClick={handleCalendarMenuClick}>
        <CalendarDots size={32} weight="bold" />
      </button>
      <button className={`f-menu-trigger f-emails-menu ${isOpenEmailsMenu ? 'active' : ''}`} onClick={handleEmailsMenuClick}>
        <Mailbox size={32} weight="bold" />
      </button>
      <button className={`f-menu-trigger f-notification-menu ${isOpenNotificationsMenu ? 'active' : ''}`} onClick={handleNotificationMenuClick}>
        <Bell size={32} weight="bold" />
      </button>
    </div>
  );
}