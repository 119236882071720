


import test from './src/window-body/test/index';
import loadingError from './src/window-body/loadingError';

export type WindowContentTemplate = {
  MenuContent: null | (() => Promise<any>),
  BodyContent: () => Promise<any>,
}
export const windowContents: Record<string, WindowContentTemplate> = {
  test,
  loadingError,
}
