import React, { useEffect, useRef } from "react";
import { useMenu } from "..";
import "../../../styles/src/menus/app/index.scss";
import { MenuHeader } from "../src/menu-header";
export function TasksMenu() {
  const { isOpen: { tasks: isOpenTasksMenu },  isFullScreen: { tasks: isFullScreenTasksMenu }, toggleFullScreen, setMenuRef } = useMenu();
  const menuRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => { setMenuRef('tasks', menuRef.current); return () => { setMenuRef('tasks', null); }; }, [setMenuRef]);
  const handleFullscreenClick = (event: React.MouseEvent<HTMLButtonElement>) => { event.stopPropagation(); toggleFullScreen('tasks'); };
  return (
    <div ref={menuRef} className={`athenorix-menu athenorix-menu-middle tasks-menu ${isOpenTasksMenu ? 'open' : ''} ${isFullScreenTasksMenu ? 'fullscreen' : ''}`}>
      <MenuHeader title="Tasks" haveFullscreen={true} handleClick={handleFullscreenClick} isInFullScreen={isFullScreenTasksMenu} isOpen={isOpenTasksMenu}></MenuHeader>
    </div>
  );
}