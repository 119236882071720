import React, { useState, useRef } from "react";
import "../../styles/src/footer/index.scss";
import { LeftMenu } from "./src/LeftMenu";
import { CenterMenu } from "./src/CenterMenu";
import { RightMenu } from "./src/RightMenu";
import { WindowManager } from '../window/src/mananger/index';


export function Footer() {

  return <div id="footer">
    <LeftMenu></LeftMenu>
    <CenterMenu></CenterMenu>
    <RightMenu></RightMenu>
  </div>
}