import { createContext, useContext } from "react";
import { ATHXWindowProps } from "../window";
import { FC, useState, useRef } from 'react';

export type createATHXWindowType = (athxWindow: FC<ATHXWindowProps>) => FC<ATHXWindowProps>;

interface ATHXWindowData extends ATHXWindowProps {
  key: string;
}
interface MiniatureWindowData {
  id: string;
  content: string; // HTML come stringa per la preview
}
export type WindowDragPositionType = null | 'top' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
interface AppContextType {

  /** Funzione per aprire una nuova finestra */
  addAthxWindow: (athxWindowData: ATHXWindowData) => void;
  /** Funzione per eliminare una finestra */
  deleteAtxhWindow: (key: string) => void;
  /** Lista delle finestre aperte */
  athxWindowsList: Array<ATHXWindowData>;
  /** Salva una finestra in miniatura per la preview */
  saveMiniatureWindow: (props: ATHXWindowProps, content: string) => void;
  /** Lista delle finestre in miniatura */
  miniatureWindowsList: Array<MiniatureWindowData>;
  /** Elimina una finestra in miniatura */
  deleteMiniatureWindow: (id: string) => void;
  /** Ultima posizione prefissata alla quale è stata portata una finestra */
  lastAthxWindowFixedPos: React.MutableRefObject<WindowDragPositionType>;
  /** Se qualche finestra */
  isAnyAthxWindowDragging: React.MutableRefObject<boolean>;
  /** ID dell'ultima finestra spostata */
  lastAthxWindowDraggedId: React.MutableRefObject<string | null>;
  /** uno stato per il rendering dei placeholder */
  isAnyAthxWindowDraggingState: boolean;
  setAnyAthxWindowDraggingState: (state: boolean) => void;
  lastAthxWindowFixedPosState: WindowDragPositionType;
  setLastAthxWindowFixedPosState: (state: WindowDragPositionType) => void;

}

const AppContext = createContext<AppContextType>({
  addAthxWindow: () => { },
  deleteAtxhWindow: () => { },
  athxWindowsList: [],
  saveMiniatureWindow: () => { },
  miniatureWindowsList: [],
  deleteMiniatureWindow: () => { },
  lastAthxWindowFixedPos: { current: null },
  isAnyAthxWindowDragging: { current: false },
  lastAthxWindowDraggedId: { current: null },
  isAnyAthxWindowDraggingState: false,
  setAnyAthxWindowDraggingState: () => { },
  lastAthxWindowFixedPosState: null,
  setLastAthxWindowFixedPosState: () => { },
  

});

export function AppProvider({ children }: { children: React.ReactNode }) {
  const [athxWindowsList, setAthsWindowsList] = useState<Array<ATHXWindowData>>([]);
  const [miniatureWindowsList, setMiniatureWindowsList] = useState<Array<MiniatureWindowData>>([]);
  const [lastAthxWindowFixedPosState, setLastAthxWindowFixedPosState] = useState<WindowDragPositionType>(null);
  
  

  const addAthxWindow = (athxWindowData: ATHXWindowData) => {
    const key = athxWindowData.key || Math.random().toString(36).substring(7);
    if (athxWindowsList.some(athxWindow => athxWindow.id === athxWindowData.id || athxWindow.key === key))
      return;
    setAthsWindowsList((prevList) => [...prevList, { ...athxWindowData, key }]);
  };

  const lastAthxWindowFixedPos = useRef<WindowDragPositionType>(null);
  const isAnyAthxWindowDragging = useRef<boolean>(false);
  const lastAthxWindowDraggedId = useRef<string | null>(null);
  const [isAnyAthxWindowDraggingState, setAnyAthxWindowDraggingState] = useState<boolean>(false);

  const saveMiniatureWindow = (props: ATHXWindowProps) => {
    // setMiniatureWindowsList((prevList) => {
    //   const existingIndex = prevList.findIndex(window => window.id === id);
    //   if (existingIndex !== -1) {
    //     // Aggiorna la miniatura esistente
    //     const updatedList = [...prevList];
    //     updatedList[existingIndex] = { id, content };
    //     return updatedList;
    //   }
    //   // Aggiungi una nuova miniatura
    //   return [...prevList, { id, content }];
    // });
  };

  const deleteAtxhWindow = (id: string) => {
    setAthsWindowsList((prevList) => prevList.filter((athxWindowData) => athxWindowData.id !== id));
  };

  const deleteMiniatureWindow = (id: string) => { };
  return (
    <AppContext.Provider value={{
      athxWindowsList,
      addAthxWindow,
      deleteAtxhWindow,
      saveMiniatureWindow,
      miniatureWindowsList,
      deleteMiniatureWindow,
      lastAthxWindowFixedPos,
      isAnyAthxWindowDragging,
      lastAthxWindowDraggedId,
      isAnyAthxWindowDraggingState,
      setAnyAthxWindowDraggingState,
      lastAthxWindowFixedPosState,
      setLastAthxWindowFixedPosState,
      // windowDragPosition,
      // changeWindowDragPosition,
      // windowDragging,
      // changeWindowDragging,
      // getCurrentDragPosition,
      // setWindowDragPosition,
      // activeWindowId,
      // setActiveWindowId
    }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppProvider() {
  return useContext(AppContext);
}
