import '../../../../../styles/src/menus/mini/src/body/index.scss';
import { Spinner, CheckCircle } from '@phosphor-icons/react';
export function ProcessMinBody() {
  return (
    <ul className="athm-process-list athm-min-menu">
      <li>
        <a href="#asdasd">
          <div className="desc">
            <Spinner size={25} className="spinner" /><span>Creazione template</span>
          </div>
          <div className="foot">
            <div> <div> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#dasdsa">
          <div className="desc">
            <CheckCircle size={25} /><span>Creazione menu</span>
          </div>
          <div className="foot">
            <div> <div className='w-100'> </div> </div>
          </div>
        </a>
      </li>
    </ul>
  );
}