import React, { useEffect, useRef } from "react";
import { useMenu } from "..";
import "../../../styles/src/menus/search/index.scss";
import { MenuHeader } from "../src/menu-header";
import { MenuBody } from "../src/menu-body";
import { SearchBody } from "./src/body";
export function SearchMenu() {
  const { isOpen: { search: isOpenSearchMenu }, isFullScreen: { search: isFullScreenSearchMenu }, toggleFullScreen, setMenuRef } = useMenu();
  const menuRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => { setMenuRef('search', menuRef.current); return () => { setMenuRef('search', null); }; }, [setMenuRef]);
  const handleFullscreenClick = (event: React.MouseEvent<HTMLButtonElement>) => { event.stopPropagation(); toggleFullScreen('search'); };
  return (
    <div ref={menuRef} className={`athenorix-menu athenorix-menu-large search-menu ${isOpenSearchMenu ? 'open' : ''} ${isFullScreenSearchMenu ? 'fullscreen' : ''}`}>
      <MenuHeader title="Ricerca" haveFullscreen={true} handleClick={handleFullscreenClick} isInFullScreen={isFullScreenSearchMenu} isOpen={isOpenSearchMenu}></MenuHeader>
      <MenuBody>
        <SearchBody />
      </MenuBody>
    </div>
  );
}