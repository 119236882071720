import { useEffect } from 'react';

export const SocketNotification = () => {
  useEffect(() => {
    // if (typeof Worker !== 'undefined') {
    // const worker = new Worker(new URL('./worker.js', import.meta.url));

    // // Invia l'URL del socket.io al worker
    // worker.postMessage({ type: 'init-socket', data: { url: 'http://localhost:3000' } });

    // // Riceve messaggi dal worker
    // worker.addEventListener('message', (event) => {
    //   const { type, message } = event.data;

    //   if (type === 'new-message') {
    // showNotification('ciao come va');
    //   }
    // });

    // return () => {
    //   worker.terminate();
    // };
    // }
  }, []);

  // const showNotification = (message: string) => {
  //   try {
  //     if (Notification.permission === 'granted') {
  //       new Notification('Nuovo messaggio', {
  //         body: message,
  //       });
  //     } else if (Notification.permission !== 'denied') {
  //       Notification.requestPermission().then((permission) => {
  //         if (permission === 'granted') {
  //           new Notification('Nuovo messaggio', {
  //             body: message,
  //           });
  //         }
  //       });
  //     }
  //   } catch (iE) {
  //     console.log(iE);
  //   }
  // };

  // showNotification('ciao come va');
  // showNotification('ciao come va');

  return null; // Questo componente non ha UI
};
