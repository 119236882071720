import React, { useEffect } from "react";
import '../../../../styles/src/dekstop/src/desktop-body/index.scss';
import { useAppProvider } from "../../../root/provider";


import { ATHXWindow } from '../../../window/index';
import { HourglassMedium } from "@phosphor-icons/react";



import { ButtonWindowOpener } from '../../../window/src/opener/index';
export const DesktopBody: React.FC = function () {

  const { athxWindowsList } = useAppProvider();




  return (

    <div className="desktop-body" id="desktop-body">

      <div className="icons-container">
        <ButtonWindowOpener key={'test2'} url={'test'} icon={HourglassMedium} title={'test2'} id={'test2'} onClose={() => { }} onToggleMinimize={() => { }} iconName="Doppio click per aprire" />
      </div>



      {athxWindowsList.map(function (windowData, i) {
        return (<ATHXWindow id={windowData.id} key={windowData.key} title={windowData.title} url={windowData.url} onClose={windowData.onClose} onToggleMinimize={windowData.onToggleMinimize} icon={windowData.icon} />)
      })}

    </div>
  );
}

/*

{[...Array(1)].map((_, i) => <Window key={`athx_w_id_${i}`} id={`athx_w_id_${i}`} title={`Test Window ${i}`} url={`https://jsonplaceholder.typicode.com/todos/${i}`} onClose={() => { }} onToggleMinimize={() => { }} />)}
   <div style={{display:'none', width:'100%',height:'100%',overflowX:'auto'}}>

      <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', padding: '.5rem', backgroundColor: 'white', alignItems: 'center' }}>
        <button className="athx-btn sm primary" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn sm accent" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn sm secondary" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn sm interactive" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn sm success" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn sm warning" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn sm error" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn sm info" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', padding: '.5rem', backgroundColor: 'white', alignItems: 'center' }}>
        <button className="athx-btn primary" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn accent" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn secondary" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn interactive" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn success" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn warning" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn error" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn info" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', padding: '.5rem', backgroundColor: 'white', alignItems: 'center' }}>
        <button className="athx-btn lg primary" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn lg accent" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn lg secondary" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn lg interactive" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn lg success" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn lg warning" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn lg error" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
        <button className="athx-btn lg info" style={{ marginLeft: '.5rem', marginRight: '.5rem' }}>ciao</button>
      </div>

      <div className="form-element-container" style={{ marginTop: '1rem' }} >
        <input type="text" id="asdf2" className="athx-input" placeholder="placeholder" autoComplete="off"/>
        <label htmlFor="asdf2">Input</label>
      </div>

      <div className="form-element-container" style={{ marginTop: '1rem' }} >
        <input type="search" id="search" className="athx-input" placeholder="placeholder" autoComplete="off" />
        <label htmlFor="search">Search</label>
      </div>

      <div className="form-element-container" style={{ marginTop: '1rem' }} >
        <input type="number" id="number" className="athx-input" placeholder="placeholder" autoComplete="off" />
        <label htmlFor="number">Number</label>
      </div>

      <div className="form-element-container" style={{ marginTop: '1rem' }} >
        <input type="file" id="file" className="athx-input" placeholder="placeholder" autoComplete="off" />
        <label htmlFor="file">Number</label>
      </div>


      <div className="form-element-container" style={{ marginTop: '1rem' }} >
        <textarea className="athx-textarea" id="asdf3" placeholder="placeholder" rows={5}></textarea>
        <label htmlFor="asdf3">Textarea</label>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', padding: '.5rem', backgroundColor: '#bbb3b3', marginTop: '1rem', alignItems: 'center' }}>
        <div className="form-element-container" >
          <label>
            <input type="checkbox" name="asdf" className="primary" />
            <span className="input-checkbox"></span>
            <span>ciao</span>
          </label>
        </div>

        <div className="form-element-container" >
          <label>
            <input type="checkbox" name="asdf" className="accent" />
            <span className="input-checkbox"></span>
            <span>ciao</span>
          </label>
        </div>

        <div className="form-element-container" >
          <label>
            <input type="checkbox" name="asdf" className="secondary" />
            <span className="input-checkbox"></span>
            <span>ciao</span>
          </label>
        </div>

        <div className="form-element-container" >
          <label>
            <input type="checkbox" name="asdf" className="interactive" />
            <span className="input-checkbox"></span>
            <span>ciao</span>
          </label>
        </div>

        <div className="form-element-container" >
          <label>
            <input type="checkbox" name="asdf" className="success" />
            <span className="input-checkbox"></span>
            <span>ciao</span>
          </label>
        </div>

        <div className="form-element-container" >
          <label>
            <input type="checkbox" name="asdf" className="warning" />
            <span className="input-checkbox"></span>
            <span>ciao</span>
          </label>
        </div>

        <div className="form-element-container" >
          <label>
            <input type="checkbox" name="asdf" className="error" />
            <span className="input-checkbox"></span>
            <span>ciao</span>
          </label>
        </div>

        <div className="form-element-container" >
          <label>
            <input type="checkbox" name="asdf" className="info" />
            <span className="input-checkbox"></span>
            <span>ciao</span>
          </label>
        </div>
      </div>


      <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', padding: '.5rem', backgroundColor: '#bbb3b3', marginTop: '1rem', alignItems: 'center' }}>

        <div className="form-element-container" >
          <label>
            <input type="radio" name="ciao" id="qwe1" className="primary" />
            <span className="input-checkbox"></span>
            <span>ciao</span>
          </label>
        </div>

        <div className="form-element-container" >
          <label>
            <input type="radio" name="ciao" id="qwe2" className="accent" />
            <span className="input-radio"></span>
            <span>ciao</span>
          </label>
        </div>

        <div className="form-element-container" >
          <label>
            <input type="radio" name="ciao" id="qwe3" className="secondary" />
            <span className="input-checkbox"></span>
            <span>ciao</span>
          </label>
        </div>

        <div className="form-element-container" >
          <label>
            <input type="radio" name="ciao" id="qwe4" className="interactive" />
            <span className="input-radio"></span>
            <span>ciao</span>
          </label>
        </div>

        <div className="form-element-container" >
          <label>
            <input type="radio" name="ciao" id="qwe5" className="success" />
            <span className="input-checkbox"></span>
            <span>ciao</span>
          </label>
        </div>

        <div className="form-element-container" >
          <label>
            <input type="radio" name="ciao" id="qwe6" className="warning" />
            <span className="input-radio"></span>
            <span>ciao</span>
          </label>
        </div>

        <div className="form-element-container" >
          <label>
            <input type="radio" name="ciao" id="qwe7" className="error" />
            <span className="input-checkbox"></span>
            <span>ciao</span>
          </label>
        </div>

        <div className="form-element-container" >
          <label>
            <input type="radio" name="ciao" id="qwe8" className="info" />
            <span className="input-radio"></span>
            <span>ciao</span>
          </label>
        </div>

      </div>




      <div style={{ width: '100%', padding: '5rem', backgroundColor: 'grey', marginTop: '2rem' }}>

      </div>

      </div>

*/
